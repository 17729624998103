import React from 'react';
import Header from '../../components/header/header';

import './pdfokuma.scss';

const Pdfokuma = () => {
    return (
        <div className="pdfokuma">
            <Header/>
            <div >
               PDF okuma
            </div>
        </div>
    )
}

export default Pdfokuma