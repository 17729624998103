import React from 'react';
import Header from '../../components/header/header';
import Slide from '../../components/slide/slide';
import './home.scss';




const Home = () => {
    const images = [
        require('../../images/2.png'),
        require('../../images/3.png'),
        require('../../images/4.png'),
        require('../../images/5.png'),
        require('../../images/6.png'),
        require('../../images/cmm1.png'),
        require('../../images/erp2.png'),
        require('../../images/hakkimizda1.png'),
      ];
    return (
        <div className="home">
            <Header/>
           
      
      <Slide images={images} height="400px" />
     
    


      <div className="homepage-container">
  <div className="hero-section">
    <h1>Geleceğin Üretim Teknolojileriyle Tanışın!</h1>
    <h2>CNC Üretiminde Dijital Dönüşüm!</h2>
    <h3>Gelişmiş ERP sistemlerimiz ve post-process yazılımlarımız ile üretim süreçlerinizi optimize edin.</h3>
    <div className="cta-buttons">
      <button className="cta-button" onClick={() => window.location.href='/erp'}>Ürünlerimizi Keşfedin</button>
      <button className="cta-button">Teklif Alın</button>
    </div>
  </div>

  <div className="about-section">
    <h2>Firmamız Hakkında</h2>
    <h3>Üretimde Akıllı Çözümler</h3>
    <p>
      Akıllı ERP ve CNC Post Sistemleri olarak, üretim sektöründeki firmaların dijital dönüşümüne katkıda bulunuyoruz. İş süreçlerinizi hızlandıran, maliyetlerinizi düşüren ve verimliliğinizi artıran yazılım çözümlerimizle, endüstriyel alanda teknoloji lideri olmayı hedefliyoruz. Ahmet Bodur tarafından kurulan firmamız, üretim hattındaki her adımı optimize etmek için yenilikçi teknolojiler geliştiriyor.
    </p>
  </div>

  <div className="products-section">
    <h2>Ürünlerimizle Geleceği Yakalayın!</h2>
    <p>
      Üretim süreçlerinizi ileri taşımak için sunduğumuz ürün ve hizmetlerimizle tanışın.
    </p>
    <h3>ERP Sistemleri:</h3>
    <p>
      Üretim sürecinin planlanması, stok yönetimi, satın alma ve iş emirlerinin takibi gibi tüm süreçleri tek bir platformda yöneten ERP çözümlerimiz, işletmenizin verimliliğini en üst düzeye çıkarır.
    </p>
    <h3>NX Post-Processing:</h3>
    <p>
      NX CAM ve diğer CAM yazılımlarında yapılan işlemleri, CNC makineleriniz için optimize edilmiş G-kodlarına dönüştürün. Hatasız, hızlı ve optimize edilmiş G-kodlarıyla işleme süreçlerinizi hızlandırın.
    </p>
  </div>

  <div className="why-choose-us-section">
    <h2>Neden Bizi Tercih Etmelisiniz?</h2>
    <ul>
      <li><strong>Uzmanlık:</strong> Üretim teknolojilerinde yılların verdiği deneyim ve bilgi birikimi.</li>
      <li><strong>Teknoloji Odaklı:</strong> En güncel ve yenilikçi çözümlerle donatılmış yazılımlar.</li>
      <li><strong>Verimlilik:</strong> Üretim süreçlerinizi optimize ederek maliyetlerinizi azaltma garantisi.</li>
      <li><strong>Destek:</strong> Müşteri odaklı yaklaşımımız ve teknik destek ekibimizle her zaman yanınızdayız.</li>
    </ul>
  </div>

  <div className="faq-section">
    <h2>Sıkça Sorulan Sorular</h2>
    <div className="faq-item">
      <h3>ERP sisteminiz hangi sektörlere uygundur?</h3>
      <p>
        ERP sistemimiz, metal işleme, otomotiv, havacılık ve çeşitli üretim sektörlerinde faaliyet gösteren firmalar için tasarlanmıştır.
      </p>
    </div>
    <div className="faq-item">
      <h3>Post-process yazılımınız hangi CNC makineleri ile uyumludur?</h3>
      <p>
        Çözümlerimiz, 3 eksenli ve 5 eksenli CNC makineleri dahil olmak üzere birçok modern makine ile uyumludur. Müşterilerimize geniş bir makine yelpazesine uygun çözümler sunmaktayız.
      </p>
    </div>
  </div>

  <div className="contact-section">
    <h2>Bize Ulaşın</h2>
    <p>
      Sizin İçin Buradayız! Her türlü sorunuz, iş birliği teklifiniz veya ürünlerimiz hakkında detaylı bilgi almak için bizimle iletişime geçin. Size en kısa sürede geri dönüş yapmaktan memnuniyet duyarız.
    </p>
    <h3>İletişim Bilgileri:</h3>
    <p><strong>Telefon:</strong> +90 537 450 2204</p>
    <p><strong>E-posta:</strong> bodurahmet8@gmail.com</p>
    <p><strong>Adres:</strong> ODTU Bilişim Sistemleri Bölümü, Ankara, Türkiye</p>
  </div>
</div>

        </div>
    )
}

export default Home