import React from 'react';

import Home from "./pages/home/home"
import Hakkimizda from './pages/hakkimizda/hakkimizda';
import Vizyon from './pages/vizyon/vizyon';
import Erpsistemleri from './pages/erpsistemleri/erpsistemleri';
import Post from './pages/post/post';
import Pdfokuma from './pages/pdfokuma/pdfokuma';
import Kiriktespit from './pages/kiriktespit/kiriktespit';
import Isilani from './pages/isilani/isilani';
import Isbasvurusu from './pages/isbasvurusu/isbasvurusu';
import Iletisim from './pages/iletisim/iletisim';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/index" element={<Home />} />
      <Route path="/hakkimizda" element={<Hakkimizda />} />
      <Route path="/vizyon" element={<Vizyon />} />
      <Route path="/erp" element={<Erpsistemleri />} />
      <Route path="/post" element={<Post />} />
      <Route path="/pdf_okuma" element={<Pdfokuma />} />
      <Route path="/kirik_tespit" element={<Kiriktespit />} />
      <Route path="/is_ilani" element={<Isilani />} />
      <Route path="/is_basvuru" element={<Isbasvurusu />} />
      <Route path="/iletisim" element={<Iletisim />} />

     

      </Routes>

      

    </Router>
  );
}

export default App;
