import React, { useState, useEffect } from 'react';
import './slide.scss'; // Import the corresponding SCSS file

const Slide = ({ images, height = '400px' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  // Automatically change the slide every 10 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval); // Clear interval on unmount
  }, []);

  return (
    <div className="slider-container" style={{ height }}>
      <div className="slider">
        <button className="prev" onClick={prevSlide}>❮</button>
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        <button className="next" onClick={nextSlide}>❯</button>
      </div>
    </div>
  );
};

export default Slide;
