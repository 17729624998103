import React from 'react';
import Header from '../../components/header/header';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './hakkimizda.scss';
import './erpsistemleri.scss'

const Hakkimizda = () => {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);

    return (
        <div className="home">
            <Header/>
            <div className="erp-container">
      <h1 className="hakkimizda-title">Hakkımızda</h1>
      <p className="hakkimizda-text">
        Akıllı ERP ve CNC Post Sistemleri olarak, endüstriyel üretim süreçlerine yenilikçi çözümler sunuyoruz. Türkiye merkezli firmamız, özellikle CNC makineleri kullanan işletmelere yönelik ERP sistemleri, post-process yazılımları ve üretim otomasyon çözümleri geliştiriyor. Üretim sürecinin her adımını optimize eden yazılımlarımızla, firmaların operasyonlarını daha verimli, daha hızlı ve daha hatasız hale getirmelerine yardımcı oluyoruz.
      </p>
      <p className="hakkimizda-text">
        Yapay zeka ve makine öğrenimi teknolojilerini kullanarak geliştirdiğimiz Auto CMM Doc ve Takım Kırılma Algılama Sistemi, üretimdeki verimliliği daha da artırmayı hedefliyor. Auto CMM Doc, teknik resimlerden ölçü ve toleransları otomatik olarak çıkarıp, Excel dosyasına aktarıyor ve her bir boyut için otomatik balonlama sağlıyor. Bu süreçte kullanılan yapay zeka algoritmaları, belgeleri hızlı ve doğru bir şekilde analiz ederek manuel hata riskini ortadan kaldırıyor.
      </p>
      <p className="hakkimizda-text">
        Takım Kırılma Algılama Sistemi, CNC makinelerinde takım kırılmalarını anında tespit eden gelişmiş bir yapay zeka sistemi ile donatılmıştır. Makine öğrenimi algoritmaları sayesinde, sistem zamanla daha doğru tahminlerde bulunarak beklenmedik duraksamaları önler, takım ömrünü uzatır ve üretim hattında verimliliği artırır.
      </p>
      <p className="hakkimizda-text">
        Kurucumuz Ahmet Bodur’un sektörel tecrübesi ve teknolojik bilgi birikimi ile kurulan şirketimiz, CNC makinelerinde kullanılan CAM dosyalarının doğru ve optimize edilmiş G-kodlarına dönüştürülmesini sağlayan post-process çözümleriyle de sektörde fark yaratmaktadır. Ayrıca, ERP sistemimizle işletmelerin süreçlerini dijitalleştiriyor ve operasyonlarını daha yönetilebilir hale getiriyoruz.
      </p>
      <h2 id="misyon" className="hakkimizda-subtitle">Misyonumuz</h2>
      <p className="hakkimizda-text">
        Metal işleme ve CNC üretim yapan firmaların dijital dönüşüm süreçlerine katkıda bulunmak, üretim süreçlerini modern yazılım çözümleri ile destekleyerek dünya standartlarında rekabet avantajı sağlamalarına yardımcı olmaktır.
      </p>
      <h2 className="hakkimizda-subtitle">Vizyonumuz</h2>
      <p className="hakkimizda-text">
        Geleceğin üretim teknolojilerini bugünden sunarak, sanayi sektöründe dünya çapında tanınan bir teknoloji lideri olmak ve işletmelerin sürdürülebilir üretim yapmalarına katkıda bulunmaktır.
      </p>
      <h2 className="hakkimizda-subtitle">Değerlerimiz</h2>
      <ul className="hakkimizda-values">
        <li>İnovasyon: Sürekli gelişen teknolojilere ayak uydurarak, müşterilerimize yenilikçi çözümler sunuyoruz.</li>
        <li>Kalite: Yazılımlarımızı yüksek kalite standartlarına göre geliştiriyor, müşterilerimize en iyiyi sunmayı hedefliyoruz.</li>
        <li>Güven: Müşterilerimizle uzun vadeli ve güvenilir iş ortaklıkları kurmayı amaçlıyoruz.</li>
        <li>Müşteri Memnuniyeti: Her projede müşterilerimizin ihtiyaçlarını ön planda tutarak çözüm üretiyoruz.</li>
      </ul>
      <p className="hakkimizda-text">
        Akıllı ERP ve CNC Post Sistemleri, üretim sektöründe dönüşümü hızlandıran ve süreçleri optimize eden çözümleri ile işletmenizin gücüne güç katmak için yanınızda!
      </p>
    </div>
        </div>
    )
}

export default Hakkimizda