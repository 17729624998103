import React from 'react';
import Header from '../../components/header/header';

import './iletisim.scss';
import '../../pages/erpsistemleri/erpsistemleri.scss';

const Iletisim = () => {
    return (
        <div>
            <Header/>
        
        
        <div className="erp-container">
             
      <h1>İletişim</h1>
      <p>Sizin İçin Buradayız!</p>

      <p>
        Her türlü sorunuz, iş birliği teklifiniz veya ürünlerimiz hakkında detaylı bilgi almak için bizimle iletişime geçin. 
        Size en kısa sürede geri dönüş yapmaktan memnuniyet duyarız.
      </p>

      <div className="iletisim-bilgileri">
        <h3>İletişim Formu ve Bilgileri:</h3>
        <p>
          <strong>Telefon:</strong> +90 537 450 2204
        </p>
        <p>
          <strong>E-posta:</strong> bodurahmet8@gmail.com
        </p>
        <p>
          <strong>Adres:</strong> ODTU Bilişim Sistemleri Bölümü, Ankara, Türkiye
        </p>
      </div>

      
        </div>
        </div>
    )
}

export default Iletisim