import React from 'react';
import Header from '../../components/header/header';

import './isilani.scss';
import './erpsistemleri.scss'

const Isilani = () => {
    return (
        <div className="isilani">
            <Header/>
            <div className="erp-container">
      <h1>Açık İş Pozisyonları</h1>
      <p>
        Şu anda açık iş pozisyonu bulunmamaktadır. Ancak, ilginiz için teşekkür ederiz. Gelecekteki fırsatlar için web sitemizi ziyaret etmeye devam edin.
      </p>
    </div>
        </div>
    )
}

export default Isilani