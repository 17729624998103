import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './header.scss';

const Header = () => {
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElHome, setAnchorElHome] = useState(null);
  const [anchorElCareer, setAnchorElCareer] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [openCareer, setOpenCareer] = useState(false);

  // Function to handle opening the "Ürünler" menu
  const handleProductsClick = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  // Function to handle opening the "Ana Sayfa" submenu
  const handleAnaSayfaClick = (event) => {
    setAnchorElHome(event.currentTarget);
  };

  // Function to handle opening the "Kariyer" submenu
  const handleCareerClick = (event) => {
    setAnchorElCareer(event.currentTarget);
  };

  // Function to close the menus
  const handleClose = () => {
    setAnchorElProducts(null);
    setAnchorElHome(null);
    setAnchorElCareer(null);
  };

  // Handle drawer toggle for mobile view
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Functions to handle submenu open/close in mobile
  const handleClickProducts = () => {
    setOpenProducts(!openProducts);
  };

  const handleClickAbout = () => {
    setOpenAbout(!openAbout);
  };

  const handleClickCareer = () => {
    setOpenCareer(!openCareer);
  };

  const drawer = (
    <div>
      <List>
        <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/'; }}>
          <ListItemText primary="Ana Sayfa" />
        </ListItem>
        
        {/* Hakkımızda with submenu */}
        <ListItem button onClick={handleClickAbout}>
          <ListItemText primary="Hakkımızda" />
          {openAbout ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAbout} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/hakkimizda'; }}>
              <ListItemText primary="Hakkımızda" />
            </ListItem>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/hakkimizda#misyon'; }}>
              <ListItemText primary="Vizyon-Misyon" />
            </ListItem>
          </List>
        </Collapse>

        {/* Ürünler with submenu */}
        <ListItem button onClick={handleClickProducts}>
          <ListItemText primary="Ürünler" />
          {openProducts ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProducts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/erp'; }}>
              <ListItemText primary="ERP Sistemi" />
            </ListItem>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/post'; }}>
              <ListItemText primary="Post-Processing" />
            </ListItem>
          </List>
        </Collapse>

        {/* Kariyer with submenu */}
        <ListItem button onClick={handleClickCareer}>
          <ListItemText primary="Kariyer" />
          {openCareer ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCareer} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/is_ilani'; }}>
              <ListItemText primary="İş İlanı" />
            </ListItem>
            <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/is_basvuru'; }}>
              <ListItemText primary="İş Başvurusu" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => { setMobileOpen(false); window.location.href = '/iletisim'; }}>
          <ListItemText primary="İletişim" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="header-container">
      <AppBar position="static">
        <Toolbar>
          <div className="company-info">
            <Typography variant="h6" className="company-name">
              Akıllı ERP ve CNC Post Sistemleri
            </Typography>
            <Typography variant="body1" className="company-slogan">
              CNC Üretiminde Dijital Dönüşüm!
            </Typography>
          </div>
          
          <div className="toolbar-items desktop-view">
            <Button color="inherit" onClick={() => window.location.href='/'}>Ana Sayfa</Button>
            <Button color="inherit" onClick={handleAnaSayfaClick}>Hakkımızda</Button>
            <Menu anchorEl={anchorElHome} open={Boolean(anchorElHome)} onClose={handleClose}>
              <MenuItem onClick={() => { handleClose(); window.location.href='/hakkimizda'; }}>Hakkımızda</MenuItem>
              <MenuItem onClick={() => { handleClose(); window.location.href='/hakkimizda#misyon'; }}>Vizyon-Misyon</MenuItem>
            </Menu>

            <Button color="inherit" onClick={handleProductsClick}>Ürünler</Button>
            <Menu anchorEl={anchorElProducts} open={Boolean(anchorElProducts)} onClose={handleClose}>
              <MenuItem onClick={() => { handleClose(); window.location.href='/erp'; }}>ERP Sistemi</MenuItem>
              <MenuItem onClick={() => { handleClose(); window.location.href='/post'; }}>Post-Processing</MenuItem>
            </Menu>

            <Button color="inherit" onClick={handleCareerClick}>Kariyer</Button>
            <Menu anchorEl={anchorElCareer} open={Boolean(anchorElCareer)} onClose={handleClose}>
              <MenuItem onClick={() => { handleClose(); window.location.href='/is_ilani'; }}>İş İlanı</MenuItem>
              <MenuItem onClick={() => { handleClose(); window.location.href='/is_basvuru'; }}>İş Başvurusu</MenuItem>
            </Menu>

            <Button color="inherit" onClick={() => window.location.href='/iletisim'}>İLETİŞİM</Button>
          </div>

          {/* Hamburger icon for mobile view */}
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle} className="mobile-view">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </div>
  );
};

export default Header;
