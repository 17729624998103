import React from 'react';
import Header from '../../components/header/header';
import hakkimizda1 from '../../images/hakkimizda1.png';
import erp2 from '../../images/erp2.png'
import image1 from '../../images/image1.png'
import './erpsistemleri.scss';

const Erpsistemleri = () => {
    return (
        <div >
           
      <Header />

            <div className="erp-container">
      <h1>ERP Sistemleri</h1>
      <h2>Üretiminizi ve Süreçlerinizi Dijitalleştirin!</h2>

      <div className="features-section">
        <div className="text-content">
          <h3>Özellikler:</h3>
          <p>
            Akıllı ERP sistemimiz, üretim süreçlerinizi en verimli şekilde yönetmenize yardımcı olmak için geliştirilmiştir.
            Stok yönetiminden sipariş takibine, üretim planlamasından kalite kontrolüne kadar işletmenizin tüm operasyonlarını
            tek bir platformda toplar. Üretim verimliliğinizi artırarak maliyetleri düşürmenize, zaman kayıplarını önlemenize ve
            her adımı optimize etmenize olanak tanır.
          </p>
        </div>
        <div className="image-container">
          <img src={hakkimizda1} alt="ERP Özellikler" className="animated-image" />
        </div>
      </div>

      <div className="features-section">
      <div className="image-container">
          <img src={erp2} alt="ERP Özellikler" className="animated-image" />
        </div>
      <div className="text-content">
      <h4>Gerçek Zamanlı Üretim İzleme:</h4>
      <p>
        Fabrikanızdaki üretim süreçlerini anlık olarak izleyebilir ve hangi aşamada neyin üretildiğini, 
        hangi makinenin çalıştığını veya durduğunu görebilirsiniz. Bu sayede duraklama süresini azaltabilir ve daha hızlı 
        kararlar alabilirsiniz.
      </p>

      <h4>Stok ve Envanter Yönetimi:</h4>
      <p>
        Malzeme akışınızı ve stok seviyelerini sürekli olarak izler, eksilen stoklar hakkında uyarılar alır ve 
        tedarik süreçlerini optimize edersiniz. Böylece, üretim kesintilerini önleyip, stok maliyetlerini düşürebilirsiniz.
      </p>

      <h4>Üretim Planlama ve Yönetimi:</h4>
      <p>
        Üretim emirlerini oluşturabilir, iş emirlerini planlayabilir ve bunları çalışanlar arasında dağıtabilirsiniz. 
        Planlanan üretim süreçlerinin sorunsuz ilerlemesini sağlar ve iş gücü optimizasyonu yapmanıza olanak tanır.
      </p>

      <h4>Kalite Kontrol ve Raporlama:</h4>
      <p>
        Üretim sürecinde kalite kontrol işlemlerini dijital olarak yapabilir, kalite verilerini otomatik olarak 
        raporlayabilir ve gerekli düzeltici aksiyonları hızla alabilirsiniz. Bu sayede ürün kalitenizi korur ve müşteri 
        memnuniyetini artırırsınız.
      </p>
      </div>
      </div>


      <div className="features-section">
      <div className="text-content">
      <h4>Tedarik Zinciri ve Sipariş Yönetimi:</h4>
      <p>
        Tedarikçilerle olan ilişkilerinizi, siparişlerinizi ve sevkiyat süreçlerinizi yönetmek için entegre çözümler 
        sunar. Siparişlerinizi ve sevkiyatlarınızı optimize ederek teslimat süreçlerinizi hızlandırabilirsiniz.
      </p>

      <h4>Finans ve Maliyet Yönetimi:</h4>
      <p>
        ERP sistemimiz, işletmenizin tüm finansal verilerini tek bir platformda toplar. Maliyetlerinizi, gelirlerinizi ve 
        harcamalarınızı izleyerek, karlılığı artırmanıza ve bütçeleme süreçlerinizi optimize etmenize yardımcı olur.
      </p>

      <h4>Personel ve İş Gücü Yönetimi:</h4>
      <p>
        Çalışanlarınızın performansını, iş dağılımlarını ve mesai sürelerini yönetebilir, çalışanlarınızın verimliliğini 
        artırmak için detaylı analizler yapabilirsiniz.
      </p>

      <h4>Gelişmiş Raporlama ve Analiz:</h4>
      <p>
        İşletmenizin tüm verilerini toplayarak, anlamlı ve kolay anlaşılır raporlar sunar. Karar alma süreçlerinizi 
        hızlandırmak ve iş stratejilerinizi optimize etmek için kullanabileceğiniz analitik araçlar sunar.
      </p>
      </div>
      <div className="image-container">
          <img src={image1} alt="ERP Özellikler" className="animated-image" />
        </div>
      </div>
      <h3>Neden Akıllı ERP Sistemini Tercih Etmelisiniz?</h3>
      <ul>
        <li><strong>Kapsamlı Entegrasyon:</strong> İşletmenizin tüm süreçlerini entegre bir platformda toplar.</li>
        <li><strong>Özelleştirilebilir Yapı:</strong> İşletmenize özel çözümler ve modüler yapı sayesinde sisteminizi kolayca özelleştirebilirsiniz.</li>
        <li><strong>Kullanıcı Dostu Arayüz:</strong> Her seviyedeki çalışanınız için basit ve anlaşılır bir kullanım sağlar.</li>
        <li><strong>Mobil Erişim:</strong> ERP sistemimize mobil cihazlarınızdan da erişim sağlayarak, süreçlerinizi her an her yerden yönetebilirsiniz.</li>
      </ul>
    </div>
        </div>
    )
}

export default Erpsistemleri