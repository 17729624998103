import React from 'react';
import Header from '../../components/header/header';
import '../../pages/erpsistemleri/erpsistemleri.scss';
import post1 from '../../images/post1.png'
import post2 from '../../images/post2.png'
import cmm1 from '../../images/3.png'
// import './post.scss';

const Post = () => {
    return (
        <div className="post">
            <Header/>
            <div className="erp-container">
      <h1>Post-Processing</h1>
      <h2>NX CAM’den G-Koduna Sorunsuz Geçiş!</h2>

      <div className="features-section">
        <div className="text-content">
          <p>
            CNC makinelerinizde kullanacağınız G-kodlarının oluşturulması, üretim sürecinin en kritik adımlarından biridir. 
            CAM (Computer Aided Manufacturing) yazılımlarında yapılan operasyonların makinelere doğru bir şekilde iletilmesi, 
            verimli ve hatasız üretim için büyük önem taşır. NX CAM ve diğer CAM yazılımlarında yapılan işleme operasyonlarının 
            sonuçlarını, CNC makinelerine özel post-process çözümlerimiz ile optimize edilmiş G-kodlarına dönüştürün. 
            Her makineye özel olarak hazırlanan bu yazılımlar, üretim süreçlerinizi hızlandırır, hataları en aza indirir ve 
            üretim kalitesini artırır.
          </p>
          </div>
        <div className="image-container">
          <img src={post1} alt="Makineye Özel Post-Process Yazılımı" className="animated-image" />
        </div>
      </div>


      <div className="features-section">
      <div className="image-container">
          <img src={post2} alt="Minimum Hata, Maksimum Verim" className="animated-image" />
        </div>
      <div className="text-content">
          <h3>Ana Özellikler:</h3>
          <p>
            <strong>Makineye Özel Post-Process Yazılımı:</strong> 
            Her CNC makinesi farklı çalışma koşullarına ve özelliklere sahiptir. Post-process çözümlerimiz, her makinenin ihtiyaçlarına göre özelleştirilmiştir. Makine parametrelerine ve operasyonlara uygun G-kodları üretir, bu sayede verimliliği artırır ve üretim sürecinde herhangi bir uyumsuzluk veya hatanın önüne geçer.
          </p>
       

     
          <p>
            <strong>G-Kod Optimizasyonu:</strong>
            CAM yazılımından gelen operasyonlar, doğrudan CNC makineleri için optimize edilir. G-kodların optimize edilmesi, daha hızlı işleme süreleri, daha az takım aşınması ve daha verimli malzeme kullanımı sağlar. Özellikle karmaşık geometrilerde ve hassas işleme gerektiren parçalarda, G-kod optimizasyonu kritik önemdedir.
          </p>
       
          <p>
            <strong>Minimum Hata, Maksimum Verim:</strong>
            Otomatik post-process işlemleri, manuel kodlama hatalarını ortadan kaldırır. Operasyonların G-kodlara doğru bir şekilde çevrilmesi sayesinde, üretim sürecinde karşılaşılabilecek hatalar minimuma indirilir. Ayrıca, bu yazılımlar üretim süreçlerinizi hızlandırarak genel verimliliğinizi artırır.
          </p>
        </div>
        
      </div>

      <div className="features-section">
        <div className="text-content">
          <p>
            <strong>CAM Yazılımı ile Tam Entegrasyon:</strong>
            NX CAM, SolidCAM, MasterCAM gibi yaygın CAM yazılımlarına tam entegrasyon sağlar. Kullanıcılar, CAM ortamında yapılan işleme operasyonlarını sorunsuz bir şekilde post-process ederek CNC makinelerine aktarabilir. Bu sayede iş akışı kesintisiz hale gelir ve zamandan tasarruf edilir.
          </p>
        
          <p>
            <strong>Desteklenen CNC Makineleri:</strong>
            Çözümlerimiz 3 eksen, 5 eksen, torna ve freze makineleri de dahil olmak üzere birçok CNC makinesiyle uyumludur. Makinenizin türüne ve karmaşıklığına göre optimize edilmiş post-process desteği sunar.
          </p>
        
          <p>
            <strong>Esnek ve Kolay Uyarlanabilir Yapı:</strong>
            CNC makinenizin ihtiyaçlarına göre kolayca özelleştirilebilen ve adapte edilebilen post-process çözümlerimiz, işletmenize özel ihtiyaçları karşılamak için esnek bir yapı sunar. Üretim hatlarındaki farklı makinelere hızla uyarlanabilir ve yeni makinelerle de sorunsuz çalışır.
          </p>
        </div>
        <div className="image-container">
          <img src={cmm1} alt="Esnek ve Kolay Uyarlanabilir Yapı" className="animated-image" />
        </div>
      </div>

      {/* Usage Areas Section */}
      <h3>Kullanım Alanları:</h3>
      <p>
        <strong>Otomotiv ve Havacılık Sektörü:</strong> Yüksek hassasiyet gerektiren üretim süreçlerinde G-kodların optimize edilmesi, hatasız üretim sağlar.
      </p>
      <p>
        <strong>Talaşlı İmalat:</strong> Tornalama ve frezeleme operasyonları için özel G-kod oluşturma ve optimizasyonuyla üretim verimliliği artırılır.
      </p>
      <p>
        <strong>Savunma Sanayi:</strong> Karmaşık geometrilere sahip parçaların hatasız işlenmesi için CNC makineleri ile tam uyum sağlar.
      </p>

      {/* Benefits Section */}
      <h3>Faydalar:</h3>
      <p>
        <strong>Hızlı ve Hassas Üretim:</strong> G-kodların doğru ve hızlı bir şekilde üretilmesi sayesinde daha kısa sürede üretim gerçekleştirilir.
      </p>
      <p>
        <strong>Takım Aşınmasının Azaltılması:</strong> Optimize edilen G-kodlar, takım aşınmasını minimuma indirerek maliyetlerinizi düşürür.
      </p>
      <p>
        <strong>Üretimde Yüksek Verimlilik:</strong> Daha hızlı işleme ve hatasız sonuçlar ile üretim hattındaki verimliliğinizi en üst düzeye çıkarır.
      </p>

      {/* Conclusion Section */}
      <p>
       
        CNC post-process çözümlerimiz ile CAM yazılımınızdan aldığınız verileri hızlı, verimli ve hatasız bir şekilde CNC makinelerinize iletebilirsiniz. Bu sayede üretim süreçlerinizi optimize ederken, kaliteyi ve hızınızı artırarak rekabet avantajı elde edersiniz.
      </p>
    </div>
        </div>
    )
}

export default Post