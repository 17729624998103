import React from 'react';
import Header from '../../components/header/header';

import './isbasvurusu.scss';
import './erpsistemleri.scss'

const Isbasvurusu = () => {
    return (
        <div className="isbasvurusu">
            <Header/>
            <div className="erp-container">
      <h1>İş Başvurusu</h1>
      <p>
        Şu anda açık iş pozisyonu bulunmamaktadır. Ancak, ilginiz için teşekkür ederiz. Gelecekteki fırsatlar için web sitemizi ziyaret etmeye devam edin.
      </p>
    </div>
        </div>
    )
}

export default Isbasvurusu