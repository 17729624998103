import React from 'react';
import Header from '../../components/header/header';

import './kiriktespit.scss';

const Kiriktespit = () => {
    return (
        <div className="kiriktespit">
            <Header/>
            <div >
               Kırık Tespit
            </div>
        </div>
    )
}

export default Kiriktespit