import React from 'react';
import Header from '../../components/header/header';

import './vizyon.scss';

const Vizyon = () => {
    return (
        <div className="vizyon">
            <Header/>
            <div >
               Vizyon
            </div>
        </div>
    )
}

export default Vizyon